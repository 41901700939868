import type { IconProps } from "../../types";

export const SearchIcon = ({
  size = 32,
  fill = "none",
  stroke = "#121212",
  ...props
}: IconProps & { stroke?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill={fill}
      {...props}
    >
      <path
        d="M9.96006 18.5065C14.2752 18.5065 17.7734 15.0084 17.7734 10.6932C17.7734 6.37803 14.2752 2.87988 9.96006 2.87988C5.64488 2.87988 2.14673 6.37803 2.14673 10.6932C2.14673 15.0084 5.64488 18.5065 9.96006 18.5065Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9334 22.7668L15.5734 16.1201"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
