import type { SerializeFrom, TypedResponse } from "@remix-run/server-runtime";

import type { ValidationErrorResponse } from "./validationErrorResponse.server";

type Return<T> = () => Promise<TypedResponse<T>>;
export const isValidationError = (
  data: unknown,
): data is SerializeFrom<Return<ValidationErrorResponse>> => {
  return (
    !!data &&
    typeof data === "object" &&
    ("globalErrors" in data || "fieldErrors" in data)
  );
};

export const validationErrorToString = (error: ValidationErrorResponse) => {
  let str = ((error.fieldErrors && Object.entries(error.fieldErrors)) || [])
    .map(([field, fieldError]) => {
      return `${field}: ${fieldError}`;
    })
    .join(", ");
  str = str.concat((error.globalErrors || []).join(", "));

  return `Error: ${str}`;
};
